import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import UpdateModal from "./UpdateModal";
import axios from "axios";
import toast from "react-hot-toast";

function UserTable({ customerOrders, onUpdate, onDelete, reload }) {
  const [filterStatus, setFilterStatus] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    budget: "",
    date: "",
    eventType: "",
    location: "",
    status: "",
  });

  const openModal = (item) => {
    setFormData(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (updatedData) => {
    console.log(updatedData);

    axios
      .patch(
        `https://api.thedate.co.in/api/v1/updateEvent/${updatedData._id}`,
        updatedData
      )
      .then((response) => {
        console.log(response);

        toast.success("Updated");
        closeModal();
      })
      .catch((error) => {
        console.error("There was an error updating the data!", error);
      });
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleFilterChange = (status) => {
    setFilterStatus(status);
  };

  const filteredOrders = customerOrders.filter((order) => {
    if (filterStatus === "All") return true;
    return order.status === filterStatus;
  });

  return (
    <div>
      <main className="table" id="customers_table">
        <section className="table__header pt-4">
          <h1 className="text-2xl font-bold">Customer's Booking</h1>
          <div className="filters p-4">
            <button
              onClick={() => handleFilterChange("All")}
              type="button"
              class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            >
              All
            </button>
           
            <button
              onClick={() => handleFilterChange("Pending")}
              type="button"
              class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            >
              Pending
            </button>
          </div>
        </section>
        <section className="table__body">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Budget</th>
                <th>Date</th>
                <th>Event Type</th>
                <th>Location</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr key={order._id}>
                  <td>{index + 1}</td>
                  <td>{order.fullName}</td>
                  <td>{order.email}</td>
                  <td>{order.phoneNumber}</td>
                  <td>{order.budget}</td>
                  <td>{formatDate(order.date)}</td>
                  <td>{order.eventType}</td>
                  <td>{order.location}</td>
                  <td>
                    <p className={`status ${order.status.toLowerCase()}`}>
                      {order.status}
                    </p>
                  </td>
                  <td>
                    <button onClick={() => openModal(order)} className="p-1">
                      <FaEdit />
                    </button>
                    <button onClick={() => onDelete(order._id)} className="p-1">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
      {isModalOpen && (
        <UpdateModal
          formData={formData}
          onClose={closeModal}
          onSubmit={handleFormSubmit}
          updatetable = {reload}
        />
      )}
    </div>
  );
}

export default UserTable;
