import React from "react";

import {
  FiClock,
  FiMapPin,
  FiMail,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiPhone,
} from "../assets/icons/vander";

export default function Tagline() {
  return (
    <>
      <div className="tagline bg-slate-900">
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="flex items-center justify-between">
              <ul className="list-none space-x-2">
                <li className="inline-flex items-center">
                  <FiClock className="text-red-500 size-4"></FiClock>
                  <span className="ms-2 text-slate-300">
                    Mon-Sat: 9am to 6pm
                  </span>
                </li>
                <li className="inline-flex items-center ms-2">
                  <FiMapPin className="text-red-500 size-4"></FiMapPin>
                  <span className="ms-2 text-slate-300">G39 Ashok Mall Bund, Garden Road Next To Ranka Jewellers, Pune-01</span>
                </li>
              </ul>

              <ul className="list-none">
                <li className="inline-flex items-center">
                  <FiMail className="text-red-500 text-xl" />
                  <a
                    href="mailto:event@thedate.co.in"
                    className="ml-2 text-slate-300 hover:text-slate-200"
                  >
                    event@thedate.co.in
                  </a>
                </li>
                <li className="inline-flex items-center ms-2">
                  <ul className="list-none space-x-3">
                    <li className="inline-flex ms-2 mb-0">
                      <a
                        href="https://www.instagram.com/date9668?igsh=MXZtMzN5eHdkbDN6"
                        className="text-slate-300 hover:text-red-500"
                      >
                        <FiInstagram
                          className="size-4 align-middle"
                          title="instagram"
                        ></FiInstagram>
                      </a>
                    </li>

                    <li className="inline-flex ms-2 mb-0">
                      <a
                        href="tel:9975088600"
                        className="text-slate-300 hover:text-red-500"
                      >
                        <FiPhone
                          className="size-4 align-middle"
                          title="phone"
                        ></FiPhone>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
