import React, { useState } from "react";
import { Link } from "react-router-dom";

import travel from "../assets/images/travel-train-station.svg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { FiPhone, FiMail, FiMapPin, FiX } from "../assets/icons/vander";
import ContactForm from "../components/contactform";

export default function Contact() {
  let [modal, setModal] = useState(false);
  return (
    <>
      <Navbar
        navclass="defaultscroll is-sticky"
        navlight={false}
        manuclass="justify-end"
      />
      <div>
        <div className="container mt-28">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div className="lg:col-span-7 md:col-span-6">
              <img
                src={travel}
                className="w-full max-w-[500px] mx-auto"
                alt=""
              />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">
                    Get in touch !
                  </h3>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.969171123493!2d73.8735358148552!3d18.53302358742145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c0655244f393%3A0x7b12d6df509cf2af!2sAshoka%20Mall%2C%20Bund%20Garden%20Road%2C%20Next%20to%20Ranka%20Jewellers%2C%20Pune%2C%20Maharashtra%20411001%2C%20India!5e0!3m2!1sen!2sin!4v1686482737412!5m2!1sen!2sin"
              style={{ border: "0" }}
              title="G39 Ashoka Mall"
              className="w-full h-[500px]"
            ></iframe>
          </div>
        </div>
      </div>
      <section className="relative lg:py-24 py-16">
        {/* <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div className="lg:col-span-7 md:col-span-6">
              <img
                src={travel}
                className="w-full max-w-[500px] mx-auto"
                alt=""
              />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">
                    Get in touch !
                  </h3>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FiPhone></FiPhone>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="h5 text-lg font-semibold">Phone</h5>
                <p className="text-slate-400 mt-3">
                For inquiries and support, please call our customer service team.
                </p>

                <div className="mt-5">
                  <Link
                    to="tel:9975088600"
                    className="text-red-500 font-medium"
                  >
                    9975088600
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FiMail></FiMail>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="h5 text-lg font-semibold">Email</h5>
                <p className="text-slate-400 mt-3">
                Reach out to us via email for any questions or feedback.
                </p>

                <div className="mt-5">
                  <Link
                    to="mailto:events.thedate@gmail.com"
                    className="text-red-500 font-medium"
                  >
                    event@thedate.co.in
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FiMapPin></FiMapPin>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="h5 text-lg font-semibold">Location</h5>
                <p className="text-slate-400 mt-3">
                  G39 ashoka mall bund. garden road next to ranka jewellers,
                  pune-01
                </p>

                <div className="mt-5">
                  <Link
                    to="#"
                    onClick={() => setModal(!modal)}
                    className="video-play-icon read-more lightbox text-red-500 font-medium"
                  >
                    View on Google map
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
      {modal && (
        <div className="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
          <div className="w-full h-full px-5 md:px-40 md-py-20 py-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55431.05581015953!2d-95.461302!3d29.735948000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c36647a52ab1%3A0x70a301678672cb!2sBriargrove%20Park%2C%20Houston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1710322657489!5m2!1sen!2sin"
              width="100%"
              height="100%"
              title="myfram"
              loading="lazy"
            ></iframe>
          </div>
          <button
            className="text-slate-400 absolute top-[20px] right-[20px]"
            onClick={() => setModal(!modal)}
          >
            <FiX className="size-5"></FiX>
          </button>
        </div>
      )}
    </>
  );
}
