import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);



const DonutChart = ({ data }) => {
  const chartData = {
    labels: ["Bookings", "Contact People", "Pending Bookings"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          data.bookings,
          data.contactPeople,
          data.pendingBookings,
          
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={chartData} />;
};

export default DonutChart;
