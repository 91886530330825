import React from "react";
import thankyou from "../../assets/EventImage/thankyou.svg";
import confettiSquare from "../../assets/EventImage/confeti_square.gif"; // Assuming you also need the confetti square image
import { Link } from "react-router-dom";

function ThankYou() {
  return (
    <div className="mt-[100px] ">
      <div
        style={{
          textAlign: "center",
          fontSize: "18px",
          lineHeight: "24px",
          margin: "0 auto",
          maxWidth: "480px",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
            marginBottom: "32px",
          }}
        >
          <div
            style={{
              position: "relative",
              zIndex: 1,
              background: `url(${thankyou}) center center no-repeat`,
              backgroundSize: "cover",
              width: "165px",
              height: "165px",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              background: `url(${confettiSquare}) center center no-repeat`,
              backgroundSize: "97% 97%",
              width: "152px",
              height: "152px",
            }}
          ></div>
        </div>

        <p>
          <strong className="">Fantastic! Your form was submitted correctly.</strong>
        </p>

        <p>You'll hear from us soon.
          <Link to={"/"} className="text-red-500">Go Back to Home Page</Link>
        </p>
      </div>
    </div>
  );
}

export default ThankYou;
