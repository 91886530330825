import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import image1 from "../../../assets/EventImage/festive-table-decorated-with-garland-branches-flowers-candles-center.jpg";
import image2 from "../../../assets/EventImage/grand-restaurant-ballroom-luxury-hotel-interior-design-is-executed-classical-style.jpg";
import image3 from "../../../assets/EventImage/restaurant-interior-with-elegant-style.jpg";
import image4 from "../../../assets/EventImage/table-newlyweds-with-lot-candles.jpg";
import outdoorProfile from "../../../assets/galleryEvent/Outdoor2.jpg";
import Navbar from "../../../components/navbar";
import DetailSidebar from "../../../components/detail-sidebar";
import Footer from "../../../components/footer";
import Switcher from "../../../components/switcher";

import { FiCamera, FiChevronUp, FiMapPin } from "../../../assets/icons/vander";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import { faqData, packages } from "../../../data/data";

export default function TourDetailOne() {
  let [activeTab, setActiveTab] = useState(1);

  let images = [image1, image2, image3, image4];
  let [photoIndex, setActiveIndex] = useState(0);
  let [isOpen, setOpen] = useState(false);

  let handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  let params = useParams();
  let id = params.id;
  let data = packages.find((item) => item.id === parseInt(id));
  return (
    <>
      <Navbar
        navclass="defaultscroll is-sticky"
        navlight={true}
        manuclass="justify-end nav-light"
      />
      <section
        className="relative table w-full items-center py-36 bg-top bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${outdoorProfile})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="text-3xl leading-normal tracking-wider font-semibold text-white">
              {data?.title
                ? data.title
                : "New Zealand’s South Island brims with majestic"}
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Event
            </li>
          </ul>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
            <div className="lg:col-span-8 md:col-span-7">
              <div className="grid grid-cols-12 gap-4">
                <div className="md:col-span-8 col-span-7">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data.image}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(0)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-4 col-span-5">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data.image1}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(1)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-4 col-span-5">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data.image2}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(2)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-8 col-span-7">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data.image3}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(3)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="text-2xl font-semibold mt-5">
                {data?.title
                  ? data.title
                  : "New Zealand’s South Island brims with majestic"}
              </h5>
              <p className="flex items-center text-slate-400 font-medium mt-2">
                <FiMapPin className="size-4 me-1"></FiMapPin> Pune
              </p>

              <div className="mt-6">
                <h5 className="text-lg font-semibold">Event Descriptions:</h5>

                <p className="text-slate-400 mt-6">
                  {data?.dese ? data.dese : "New Zealand"}
                </p>
              </div>

              <div className="mt-6">
                <h5 className="text-lg font-semibold">Questions & Answers:</h5>

                <div className="mt-6">
                  {faqData.slice(0, 4).map((item, index) => {
                    return (
                      <div
                        className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4 first:mt-0"
                        key={index}
                      >
                        <h2 className="text-base font-semibold">
                          <button
                            type="button"
                            className={`flex justify-between items-center p-5 w-full font-medium text-start ${
                              activeTab === item.id
                                ? "bg-gray-50 dark:bg-slate-800 text-red-500"
                                : ""
                            }`}
                            onClick={() => setActiveTab(item.id)}
                          >
                            <span>{item.title}</span>
                            <FiChevronUp
                              className={`size-4 shrink-0 ${
                                activeTab === item.id ? "" : "rotate-180"
                              }`}
                            />
                          </button>
                        </h2>
                        <div className={activeTab === item.id ? "" : "hidden"}>
                          <div className="p-5">
                            <p className="text-slate-400 dark:text-gray-400">
                              {item.desc}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-6">
                {/* <h5 className="text-lg font-semibold">Leave A Comment:</h5> */}

                {/* <form className="mt-6">
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="lg:col-span-6 mb-5">
                      <div className="text-left">
                        <label htmlFor="name" className="font-semibold">
                          Your Name:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Name :"
                        />
                      </div>
                    </div>

                    <div className="lg:col-span-6 mb-5">
                      <div className="text-left">
                        <label htmlFor="email" className="font-semibold">
                          Your Email:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Email :"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1">
                    <div className="mb-5">
                      <div className="text-left">
                        <label htmlFor="comments" className="font-semibold">
                          Your Comment:
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          className="mt-3 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 h-28"
                          placeholder="Message :"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md w-full"
                  >
                    Send Message
                  </button>
                </form> */}
              </div>
            </div>

            <DetailSidebar />
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
}
