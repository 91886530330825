import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import toast from "react-hot-toast";

const OcassionList = () => {
  const [services, setServices] = useState([]);
  const [ocassion, setocassion] = useState("");
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [update, setUpdate] = useState(false);

  const fetchServices = () => {
    axios
      .get("https://api.thedate.co.in/api/v1/getOccasion")
      .then((response) => {
        setServices(response.data.Data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        toast.error("Error fetching services");
      });
  };

  const createService = () => {
    if (!ocassion.trim()) {
      toast.error("Service name cannot be empty");
      return;
    }

    axios
      .post("https://api.thedate.co.in/api/v1/createOcassion", { ocassion })
      .then((response) => {
        setUpdate((prev) => !prev);
        setocassion("");
        toast.success("Service created successfully");
      })
      .catch((error) => {
        console.error("Error creating service:", error);
        toast.error("Error creating service");
      });
  };

  const updateService = (id) => {
    if (!ocassion.trim()) {
      toast.error("Service name cannot be empty");
      return;
    }

    axios
      .patch(`https://api.thedate.co.in/api/v1/updateOcassion/${id}`, { ocassion })
      .then((response) => {
        setUpdate((prev) => !prev);
        setocassion("");
        setEditingServiceId(null);
        toast.success("Service updated successfully");
      })
      .catch((error) => {
        console.error("Error updating service:", error);
        toast.error("Error updating service");
      });
  };

  const deleteService = (id) => {
    axios
      .delete(`https://api.thedate.co.in/api/v1/deleteOcassion/${id}`)
      .then((response) => {
        setUpdate((prev) => !prev);
        toast.success("Service deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
        toast.error("Error deleting service");
      });
  };

  useEffect(() => {
    fetchServices();
  }, [update]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingServiceId) {
      updateService(editingServiceId);
    } else {
      createService();
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow-md ">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold mb-4">Ocassion List</h1>

        <p className="mb-4">
          This table contains a list of occasions. Each entry represents a
          unique occasions that has been added to the list, ensuring comprehensive
          and efficient occasions management.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center">
        <form onSubmit={handleSubmit} className="mb-4">
          <input
            type="text"
            value={ocassion}
            onChange={(e) => setocassion(e.target.value)}
            className="p-2 border border-gray-300 rounded mr-2"
            placeholder="Occasion name"
          />
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            {editingServiceId ? "Update Occasion" : "Add Occasion"}
          </button>
        </form>
      </div>

      <ul>
        {services.map((service) => (
          <li
            key={service._id}
            className="flex items-center justify-between mb-2 p-2 bg-gray-100 rounded"
          >
            <span>{service.ocassion}</span>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => {
                  setocassion(service.ocassion);
                  setEditingServiceId(service._id);
                }}
                className="text-blue-500"
              >
                <FaEdit />
              </button>
              <button
                onClick={() => deleteService(service._id)}
                className="text-red-500"
              >
                <FaTrash />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OcassionList;
