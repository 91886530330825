import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaCalendarCheck,
  FaBoxOpen,
  FaPhone,
  FaDollarSign,
} from "react-icons/fa";
import toast from "react-hot-toast";
import Navbaradmin from "../components/Admin/Navbaradmin";
import Table from "../components/Admin/Table";
import UserTable from "../components/Admin/UserTable";
import DonutChart from "../components/Admin/DonutChart";
import Modal from "../components/Admin/Modal";

const AdminHub = () => {
  const [showTableContent, setShowTableContent] = useState(false);
  const toggleTableContent = () => {
    setShowTableContent(!showTableContent);
  };
  const toggleUpdate = () => {
    setUpdate((prev) => !prev);
  };
  const [showModal, setshowModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [update, setUpdate] = useState(false);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [pendingBookings, setPendingBookings] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);
  const [tableType, setTableType] = useState(false);

  const serviceList = () => {
    setTableType(true);
  };
  const ocassionList = () => {
    setTableType(false);
  };

  const showModalTable = () => {
    setshowModal(!showModal);
  };

  const fetchCustomerOrders = () => {
    axios
      .get("https://api.thedate.co.in/api/v1/getAllEvent")
      .then((response) => {
        const orders = response.data.Data;
        setCustomerOrders(orders);

        // Calculate pending bookings
        const pending = orders.filter((order) => order.status === "Pending");
        setPendingBookings(pending.length);

        // Calculate total budget for completed orders
        const completedOrders = orders.filter(
          (order) => order.status === "Completed"
        );
        const totalBudget = completedOrders.reduce(
          (total, order) => total + order.budget,
          0
        );
        setTotalBudget(totalBudget);
      })
      .catch((error) => {
        console.error("Error fetching customer orders:", error);
      });
  };

  useEffect(() => {
    fetchContacts();
    fetchCustomerOrders();
  }, [update, showTableContent]);

  const handleUpdate = (id) => {
    // Logic for handling update action
    console.log("Update order with id:", id);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://api.thedate.co.in/api/v1/deleteEvent/${id}`)
      .then((response) => {
        setUpdate((prev) => !prev);
        toast.success("Order deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
        toast.error("Error deleting order");
      });
  };

  const fetchContacts = () => {
    axios
      .get("https://api.thedate.co.in/api/v1/getAllContact")
      .then((response) => {
        setContacts(response.data.Data);
      })
      .catch((error) => {
        console.error("Error fetching contact us data:", error);
      });
  };

  const deleteContact = (id) => {
    axios
      .delete(`https://api.thedate.co.in/api/v1/deleteContact/${id}`)
      .then((response) => {
        setUpdate((prev) => !prev);
        toast.success("Query Deleted");
      })
      .catch((error) => {
        console.error("Error deleting contact:", error);
        toast.error("Error deleting contact");
      });
  };

  const data = {
    bookings: customerOrders.length,
    contactPeople: contacts.length,
    pendingBookings: pendingBookings,
    totalBudget: totalBudget,
  };

  return (
    <div
      // className={`${
      //   showModal
      //     ? "fixed inset-0 bg-opacity-40 backdrop-blur-md"
      //     : ""
      // }`}
    >
      <div>
        <Navbaradmin
          navclass="defaultscroll is-sticky"
          navlight={false}
          manuclass="justify-end"
          onButtonClick={toggleTableContent}
          onOpen={showModalTable}
          onService={serviceList}
          onOcassion={ocassionList}
        />
      </div>
      <div className="mt-28 flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 h-auto lg:h-[350px]">
          <div className="mx-auto flex flex-col max-w-4xl h-full">
            <div className="flex h-[180px]">
              <div className="flex-1 border p-4 m-2 bg-blue-100 flex flex-col items-center shadow-lg rounded-lg flex-wrap">
                <FaCalendarCheck className="text-4xl text-blue-500" />
                <h2 className="mt-2 text-xl font-semibold">
                  Number of Bookings
                </h2>
                <p className="mt-2 text-2xl">{data.bookings}</p>
              </div>
              <div className="flex-1 border p-4 m-2 bg-yellow-100 flex flex-col items-center shadow-lg rounded-lg flex-wrap">
                <FaBoxOpen className="text-4xl text-yellow-500" />
                <h2 className="mt-2 text-xl font-semibold">Pending Booking</h2>
                <p className="mt-2 text-2xl">{data.pendingBookings}</p>
              </div>
            </div>
            <div className="flex h-[180px]">
              <div className="flex-1 border p-4 m-2 bg-green-100 flex flex-col items-center shadow-lg rounded-lg flex-wrap">
                <FaPhone className="text-4xl text-green-500" />
                <h2 className="mt-2 text-xl font-semibold">Contact Us</h2>
                <p className="mt-2 text-2xl">{data.contactPeople}</p>
              </div>
              <div className="flex-1 border p-4 m-2 bg-red-100 flex flex-col items-center shadow-lg rounded-lg flex-wrap">
                <FaDollarSign className="text-4xl text-red-500" />
                <h2 className="mt-2 text-xl font-semibold">Total Budget</h2>
                <p className="mt-2 text-2xl">{data.totalBudget}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 h-auto lg:h-[335px] mx-auto border p-4 m-2 rounded-lg flex flex-wrap justify-center items-center shadow-lg">
          <DonutChart data={data} />
        </div>
      </div>
      <div className="mt-[70px] mx-3">
        {showTableContent ? (
          <Table contacts={contacts} deleteContact={deleteContact} />
        ) : (
          <UserTable
            customerOrders={customerOrders}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            reload={toggleUpdate}
          />
        )}
      </div>
      {showModal && (
        <Modal onClose={() => setshowModal(false)} listType={tableType} />
      )}
    </div>
  );
};

export default AdminHub;
