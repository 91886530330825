import React, { useState } from "react";
import { Link } from "react-router-dom";

import thedatelogo from "../../assets/EventImage/thedatelogo.png";

export default function Navbaradmin({
  navclass,
  navlight,
  manuclass,
  onButtonClick,
  onOpen,
  onService,
  onOcassion
}) {
  let [scrolling, setScrolling] = useState(false);
  let [isToggle, setToggle] = useState(false);

  const [currentTable, setCurrentTable] = useState(1);
  const [buttonText, setButtonText] = useState("Contact Us");

  const handleTableChange = () => {
    setCurrentTable(currentTable === 1 ? 2 : 1);
    setButtonText(currentTable === 1 ? "Bookings" : "Contact Us");
  };

  return (
    <>
      <nav
        id="topnav"
        className={`${navclass} ${scrolling ? "nav-sticky " : "bg-blue-200"}`}
      >
        <div className="container  ">
          {navlight && (
            <Link className="logo" to="/">
              <span className="inline-block dark:hidden">
                <img src={thedatelogo} className="h-12 l-dark" alt="" />
                <img src={thedatelogo} className="h-12 l-light" alt="" />
              </span>
              <img
                src={thedatelogo}
                className="h-12 hidden dark:inline-block"
                alt=""
              />
            </Link>
          )}
          {!navlight && (
            <Link className="logo" to="/">
              <div>
                <img
                  src={thedatelogo}
                  className="h-7 inline-block dark:hidden"
                  alt=""
                />
                <img
                  src={thedatelogo}
                  className="h-7 hidden dark:inline-block"
                  alt=""
                />
              </div>
            </Link>
          )}

          <div
            id="navigation"
            style={{ display: isToggle === true ? "block" : "none" }}
          >
            <ul className={`navigation-menu ${manuclass}`}>
              <li>
                <Link to="/" className="text-black">
                  Home
                </Link>
              </li>

              <li className={``}>
                <Link
                  to=""
                  className="sub-menu-item text-black"
                  onClick={() => {
                    onOpen();
                    onService();
                  }}
                >
                  Services
                </Link>
              </li>
              <li className={``}>
                <Link
                  to=""
                  className="sub-menu-item text-black"
                  onClick={() => {
                    onOpen();
                    onOcassion();
                  }}
                >
                  Ocassion
                </Link>
              </li>
              <li className={``} onClick={onButtonClick}>
                <Link to="" className=" text-black" onClick={handleTableChange}>
                  {buttonText}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
