import React from "react";
import { RxCross2 } from "react-icons/rx";
import TodoList from "./TodoList";
import OcassionList from "./OcassionList";

function Modal({ onClose, listType }) {
  return (
    <div className="fixed text-black inset-0 bg-opacity-40 backdrop-blur-md flex justify-center  overflow-y-auto">
      <div className="mt-20 flex flex-col p-4  rounded-lg">
        <button onClick={onClose} className="place-self-end">
          <RxCross2 size={30} />
        </button>
        <div>{listType ? <TodoList /> : <OcassionList />}</div>
      </div>
    </div>
  );
}

export default Modal;
