// UpdateModal.js
import React, { useState } from "react";
import { eventTypes, location } from "../../data/databooking";
import { FaTimes } from "react-icons/fa";

function UpdateModal({ formData, onClose, onSubmit, updatetable }) {
  const [localFormData, setLocalFormData] = useState(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(localFormData);
    updatetable();
  };

  return (
    <div className="fixed text-black inset-0 bg-opacity-40 backdrop-blur-md flex justify-center items-center">
      <div
        className="bg-white rounded shadow-lg w-1/2 overflow-y-auto"
        style={{ maxHeight: "calc(80vh - 3rem)" }}
      >
        <div className="flex justify-center items-center  min-h-[100vh] slide-inner w-full slide-bg-image bg-center ">
          <div className="relative max-w-[700px] w-[100%] rounded-md p-[30px]  bg-slate-50 booking-container">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl ">Update Booking</h2>
              <button
                onClick={onClose}
                className="text-gray-600 hover:text-gray-900"
              >
                <FaTimes size={24} />
              </button>
            </div>

            <div className=" booking-details mt-[16px] min-h-[390px] ">
              <div className="w-[100%] mt-[20px]">
                <label className="border-[#747171] font-medium text-[1.15rem]">
                  Full Name
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={localFormData.fullName}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd] mt-[8px]"
                  required
                />
              </div>
              <div className="w-[100%] mt-[20px]">
                <label className="border-[#747171] font-medium text-[1.15rem]">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={localFormData.email}
                  onChange={handleChange}
                  placeholder="Enter your Email"
                  className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd]"
                  required
                />
              </div>

              <div className="flex space-x-2">
                <div className="w-[100%] mt-[20px]">
                  <label className="border-[#747171] font-medium text-[1.15rem]">
                    Budget
                  </label>
                  <input
                    type="number"
                    name="budget"
                    value={localFormData.budget}
                    onChange={handleChange}
                    placeholder="Enter your budget"
                    className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd] mt-[8px]"
                    required
                  />
                </div>
                <div className="w-[100%] mt-[20px]">
                  <label className="border-[#747171] font-medium text-[1.15rem]">
                    Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    value={localFormData.date}
                    onChange={handleChange}
                    placeholder="Enter your Date of Event"
                    className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd] mt-[8px]"
                    required
                  />
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="w-[100%] mt-[20px]">
                  <label className="border-[#747171] font-medium text-[1.15rem]">
                    Select Event Type
                  </label>
                  <select
                    name="eventType"
                    value={localFormData.eventType}
                    onChange={handleChange}
                    className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd] mt-[8px]"
                  >
                    {eventTypes.map((eventType, index) => (
                      <option key={index} value={eventType}>
                        {eventType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-[100%] mt-[20px]">
                  <label className="border-[#747171] font-medium text-[1.15rem]">
                    Location
                  </label>
                  <select
                    name="location"
                    value={localFormData.location}
                    onChange={handleChange}
                    className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd] mt-[8px]"
                  >
                    {location.map((eventType, index) => (
                      <option key={index} value={eventType}>
                        {eventType}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="w-[100%] mt-[20px]">
                  <label className="block mb-2">Status</label>
                  <select
                    name="status"
                    value={localFormData.status}
                    onChange={handleChange}
                    className="border p-2 w-full"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Completed">Booked</option>
                  </select>
                </div>
                <div className="w-[100%] mt-[20px]">
                  <label className="border-[#747171] font-medium text-[1.15rem]">
                    PHONE NUMBER
                  </label>
                  <input
                    type="number"
                    name="phoneNumber"
                    value={localFormData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                    className="relative w-[100%] h-[50px] rounded-md p-[10px] border border-[#ddd] mt-[8px]"
                    required
                  />
                </div>
              </div>
            </div>
            <button
              className="relative w-[100%] py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md mt-4 "
              onClick={handleSubmit}
            >
              Sumbit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateModal;
