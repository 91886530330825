import React from "react";
import { FaTrash } from "react-icons/fa";

function ContactUsTable({ contacts, deleteContact }) {
  return (
    <div>
      <main className="table" id="contact_us_table">
        <section className="table__header">
          <h1 className="text-2xl font-bold">Queries</h1>
        </section>
        <section className="table__body">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Conatct No.</th>

                <th>Query </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, index) => (
                <tr key={contact._id}>
                  <td>{index + 1}</td>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>

                  <td>{contact.comments}</td>
                  <td>
                    <button onClick={() => deleteContact(contact._id)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
}

export default ContactUsTable;
