import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import thedatelogo from "../assets/EventImage/thedatelogo.png";

import { FiSearch } from "../assets/icons/vander";

export default function Navbar({ navclass, navlight, manuclass }) {
  let [scrolling, setScrolling] = useState(false);
  let [isToggle, setToggle] = useState(false);
  let [manu, setManu] = useState("");
  let [subManu, setSubManu] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [userManu, setUserManu] = useState(false);

  let dropdownRef = useRef(null);
  let userRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 50;
      setScrolling(isScrolling);
    };

    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const userOutsideClick = (e) => {
      if (userRef.current && !userRef.current.contains(e.target)) {
        setUserManu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleOutsideClick);
    window.addEventListener("click", userOutsideClick);

    let current = window.location.pathname;
    setManu(current);
    setSubManu(current);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("click", userOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setToggle(!isToggle);
  };

  return (
    <nav
      id="topnav"
      className={`${navclass} ${scrolling ? "nav-sticky " : ""}`}
    >
      <div className="container relative">
        {navlight && (
          <Link className="logo" to="/">
            <span className="inline-block dark:hidden">
              <img src={thedatelogo} className="h-16 l-dark" alt="" />
              <img src={thedatelogo} className="h-16 l-light" alt="" />
            </span>
            <img
              src={thedatelogo}
              className="h-16 hidden dark:inline-block"
              alt=""
            />
          </Link>
        )}
        {!navlight && (
          <Link className="logo" to="/">
            <div>
              <img
                src={thedatelogo}
                className="h-7 inline-block dark:hidden"
                alt=""
              />
              <img
                src={thedatelogo}
                className="h-7 hidden dark:inline-block"
                alt=""
              />
            </div>
          </Link>
        )}

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className={`navbar-toggle ${isToggle ? "open" : ""}`}
              id="isToggle"
              onClick={() => toggleMenu()}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div
          id="navigation"
          style={{ display: isToggle === true ? "block" : "none" }}
        >
          <ul className={`navigation-menu ${manuclass}`}>
            <li
              className={`has-submenu parent-menu-item ${
                ["/", "/index-two"].includes(manu) ? "active" : ""
              }`}
            >
              <Link
                to="/"
                onClick={() =>
                  setSubManu(setManu === "/index-item" ? "" : "/index-item")
                }
              >
                Home
              </Link>
            </li>

            <li className={`${manu === "/aboutus" ? "active" : ""}`}>
              <Link to="/aboutus" className="sub-menu-item">
                About Us
              </Link>
            </li>
            <li className={`${manu === "/allevent" ? "active" : ""}`}>
              <Link to="/allevent" className="sub-menu-item">
                Events
              </Link>
            </li>
            <li className={`${manu === "/contact" ? "active" : ""}`}>
              <Link to="/contact" className="sub-menu-item">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
